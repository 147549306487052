<template>
  <div>
    <!-- <el-dialog :visible.sync="isShow" @open="opens" :destroy-on-close=true>
      <Echarts :charData="EnlargeData" :is-axis-chart="EnlargeDataAxis" style="height:280px"></Echarts>
    </el-dialog> -->

    <el-row :gutter="20" class="home">
      <el-col :span="8" style="margin-top:20px">

<!--          左上角小表格             -->
        <el-card shadow="hover">
          <div class="user">
            <img :src="userImg" alt="" />
            <div class="userinfo">
              <p class="name">{{nickName}}</p>
              <p class="access">{{admin}}</p>
            </div>
          </div>
          <div class="login-info">
            <p>上次更新时间:<span>{{UpdateTime}}</span></p>
            <p>上次登录地点:<span>{{currentLocation}}</span></p>
          </div>
        </el-card>
<!-- -----------------------------------------------------------------    -->
        <!--           左下角小表格         -->
        <el-card style="margin-top:20px; min-height:430px;position: relative" >
          <el-table height="400" :data="tableData" >
            <el-table-column v-for="(item, index) in tableLabel" :key="index" :prop="index" :label="item">
            </el-table-column>
          </el-table>
        </el-card>
<!-- -----------------------------------------------------------------    -->

      </el-col>

<!--                        右上角数量统计                        -->
      <el-col :span="16" style="margin-top:20px">
        <div class="num">
          <el-card v-for="item in countData" :key="item.name" :body-style="{display:'flex',padding:0}">
            <i class="icon" :class="`el-icon-${item.icon}`" :style="{background:item.color}"></i>
            <div class="detail">
              <p class="num">￥{{item.value}}</p>
              <p class="txt">{{item.name}}</p>
            </div>
          </el-card>
        </div>
<!-- ----------------------------------------------------------------  -->

<!--                          右中折线统计                        -->
        <el-card style="height:280px;background-image: url(./星空.jpg);">
          <!-- ref属性是用来操作dom的 -->
          <!-- <div ref="echarts" style="height:280px"></div> -->
          <div @dblclick="Enlarge(echartData.order,true)">
            <Echarts :charData="echartData.order" style="height:280px">
            </Echarts>
          </div>

        </el-card>
<!-- ----------------------------------------------------------------  -->

<!--           右下角图标                -->
        <div class="graph">
          <el-card style="background-color: darkseagreen;">
            <!-- <div ref="userEcharts" style="height:240px"></div> -->
            <div @dblclick="Enlarge(echartData.user,true)">
              <Echarts :charData="echartData.user" style="height:280px"></Echarts>
            </div>

          </el-card>
          <el-card style="background-color: pink;">
            <!-- <div ref="pieEcharts" style="height:240px"></div> -->
            <div @dblclick="Enlarge(echartData.video,false)">
              <Echarts :charData="echartData.video" :is-axis-chart="false" style="height:240px"></Echarts>
            </div>
          </el-card>
        </div>
 <!-- -----------------------------------------------------------------    -->
      </el-col>
    </el-row>
  </div>

</template>

<script>
import * as echarts from 'echarts'
import time from '../../utils/time'
import Echarts from '@/components/Echarts'
import { mapState } from 'vuex'
export default {
  name: 'home',
  components: {
    Echarts
  },
  data() {
    return {
      currentLocation: '正在获取位置信息...', // 当前位置信息，默认显示为正在获取位置信息
      isShow: false,
      EnlargeData: {},
      EnlargeDataAxis: true,
      // userImg: require('../../assets/images/OIP-C.jpg'),
      tableData: [],
      tableLabel: {
        name: '标题',
        time: '购买时间',
        price: '价格',
        rate: '评分'
      },
      countData: [
        {
          name: '今日上架订单',
          value: 1234,
          icon: 'success',
          color: '#2ec7c9'
        },
        {
          name: '今日新增用户',
          value: 210,
          icon: 'star-on',
          color: '#ffb980'
        },
        {
          name: '今日完成订单',
          value: 1234,
          icon: 's-goods',
          color: '#5ab1ef'
        },
        {
          name: '本月上架订单',
          value: 1234,
          icon: 'success',
          color: '#2ec7c9'
        },
        {
          name: '本月新增用户',
          value: 210,
          icon: 'star-on',
          color: '#ffb980'
        },
        {
          name: '本月完成订单',
          value: 1234,
          icon: 's-goods',
          color: '#5ab1ef'
        }
      ],
      echartData: {
        order: {
          xData: [],
          series: []
        },
        user: {
          xData: [],
          series: []
        },
        video: {
          series: []
        }
      }
    }
  },
  computed: {
    ...mapState({//从this.$store.state中获取值
      nickName: state => state.user.info.nickName,
      admin: state => (state.user.info.root == true ? '超级管理员' : '管理员'),
      userImg: state => state.user.info.avator,
      UpdateTime: state => time.formatTime(state.user.info.time, 'Y/M/D h:m:s')
    })
  },
  mounted() {
    this.getPageDate()
    this.getCurrentLocation() // 添加这一行来获取位置信息
  },
  methods: {
    getCurrentLocation(){
      if (navigator.geolocation) { // 检查浏览器是否支持 Geolocation API
        navigator.geolocation.getCurrentPosition(
            position => {
              // 当位置信息获取成功时的回调函数
              const latitude = position.coords.latitude.toFixed(6);
              const longitude = position.coords.longitude.toFixed(6);
              // const apiKey = '7ac4fea54dc050cb';   //极速key
              const apiKey = 'Pfzg2n1muxQTYvLusX3KZKmT4WtFfZ48';   //百度key


              // const url = `https://jisuapi.mr-lin.online?lat=${latitude}&lng=${longitude}&type=baidu&appkey=${apiKey}`;  //极速接口
              const url = `https://baiduapi.mrlin.online?ak=${apiKey}&output=json&coordtype=wgs84ll&location=${latitude},${longitude}`;  //百度接口


              console.log('地址为：'+url);

              fetch(url, { mode: 'cors' })    //请求目标api地址
                  .then(response => response.json())         //将响应体转换为json数据
                  .then(data => {
                    // console.log('你的status为'+data.status);
                    // console.log('你的result为'+data.result);
                    // console.log('你的address为'+data.result.formatted_address);

                    // if (data.status === 0 && data.result && data.result.address) {      //检查data对象中的status字段是否等于字符串'0'，并且检查data.result.address是否存在
                    //   this.currentLocation = data.result.address;
                    // }   //极速判断
                    if (data.status === 0 && data.result && data.result.formatted_address) {      //检查data对象中的status字段是否等于字符串'0'，并且检查data.result.address是否存在
                      this.currentLocation = data.result.formatted_address;
                    }  //百度判断
                    else {
                      console.error('获取地址信息失败:', data.info);
                      this.currentLocation = '无法获取位置信息';
                    }
                  })
                  .catch(error => {
                    console.error('获取地址信息失败:', error);
                    this.currentLocation = '无法获取位置信息';
                  });
            },
            error => {
              // 当位置信息获取失败时的回调函数
              console.error('获取位置信息失败:', error.message);
              this.currentLocation = '无法获取位置信息';
            }
        );
      } else {
        // 如果浏览器不支持 Geolocation API
        console.error('浏览器不支持获取位置信息');
        this.currentLocation = '浏览器不支持获取位置信息';
      }
    },
    opens(echartData, isAxisChart) {
      this.$nextTick(() => {
        this.Enlarge(echartData, isAxisChart)
      })
    },
    Enlarge(echartData, isAxisChart) {
      this.EnlargeData = echartData
      this.EnlargeDataAxis = isAxisChart
      this.isShow = true
      console.log(echartData)
      console.log(isAxisChart)
    },
    getPageDate() {
      this.$api.getFinishOrOder().then(res => {
        this.tableData = res.map(item => {
          item.time = time.getNowTime(item.time)
          if (item.rate == 0) {
            item.rate = '无评价'
          }
          return item
        })
      })
      this.$api.getCardCount().then(res => {
        console.log(res)
        this.countData.forEach((item, index) => {
          item.value = res[index]
        })
      })
      this.$api.getPie().then(res => {
        console.log(res)
        this.videoData = res
        this.echartData.video.series = [{ data: res, type: 'pie' }]
        // })
      })
      this.$api.getLine().then(res => {
        console.log(res)
        res = {
          xdata: ['2022-11-12', '2022-11-13', '2022-11-14', '2022-11-15', '2022-11-16', '2022-11-17', '2022-11-18'],
          Array: [
            { name: '上架中', data: [2, 4, 5, 7, 9, 4, 1], type: 'line' },
            { name: '等待发货', data: [3, 9, 10, 3, 7, 4, 5], type: 'line' },
            { name: '交易完成', data: [4, 1, 2, 5, 8, 7, 9], type: 'line' },
            { name: '取消中订单', data: [2, 4, 5, 9, 6, 4, 1], type: 'line' },
            { name: '已取消订单', data: [1, 2, 10, 5, 3, 1, 6], type: 'line' },
            { name: '下架商品', data: [1, 3, 5, 4, 9, 5, 2], type: 'line' }
          ]
        }
        this.echartData.order.xData = res.xdata
        this.echartData.order.series = res.Array
        // })
      })
      this.$api.getBar().then(res => {
        console.log(res)
        // this.echartData.order.xData = res.xdata
        // this.echartData.order.series = res.Array
        res = {
          xdata: ['11-12', '11-13', '11-14', '11-15', '11-16', '11-17', '11-18'],
          Array: [
            { user: 10, order: 20 },
            { user: 10, order: 10 },
            { user: 11, order: 12 },
            { user: 10, order: 12 },
            { user: 12, order: 13 },
            { user: 2, order: 14 },
            { user: 1, order: 16 }
          ]
        }
        this.echartData.user.xData = res.xdata
        this.echartData.user.series = [
          {
            name: '新增用户',
            data: res.Array.map(item => item.user),
            type: 'bar'
          },
          {
            name: '新增订单',
            data: res.Array.map(item => item.order),
            type: 'bar'
          }
        ]
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-table__footer-wrapper,
.el-table__header-wrapper {
  overflow: hidden;
  height: 100px;
}
.home {
  .user {
    height: 200px;
    display: flex;
    align-items: center;
    // justify-content:space-around;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin-right: 40px;
    }
    .userinfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name {
        height: 50px;
        margin-top: 20px;
        font-size: 32px;
        margin-bottom: 10px;
      }
      .access {
        color: #999999;
      }
    }
  }
  .login-info {
    text-align: start;
    p {
      line-height: 28px;
      font-size: 14px;
      color: #999999;
      span {
        color: #666666;
        margin-left: 60px;
      }
    }
  }
  .num {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .el-card {
      // height: 80px;
      width: 32%;
      margin-bottom: 20px;
    }
    .icon {
      font-size: 30px;
      width: 80px;
      height: 80px;
      text-align: center;
      line-height: 80px;
      color: #fff;
    }
    .detail {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80px;
      .num {
        // text-align: start;
        padding-top: 5px;
        height: 30px;
        font-size: 30px;
        margin-bottom: 10px;
      }
      .txt {
        font-size: 14px;
        text-align: center;
        color: #999999;
      }
    }
  }
  .graph {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .el-card {
      width: 48%;
      height: 260px;
    }
  }
}
.el-table th.el-table__cell > .cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: top;
  padding-top: 14px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}
.el-table__footer-wrapper,
.el-table__header-wrapper {
  overflow: hidden;
  height: 65px;
  /* text-align: center; */
}
</style>