import { get } from "../utils/request"
import { post } from "../utils/request"

const api = {
    /**
     * 登陆接口
     */
    login(params) {   //请求后端url为login的方法
        return post("/login", params)
    },
    /**
     * 注册接口
     */
    register(params) {
        return post("/register", params);
    },
    /* 
      首页接口
    */

    /**
     * 获取已完成的订单
     */
    getFinishOrOder() {
        return get("/getFinishOrder")
    },
    getAdminById(params) {
        return get('/getAdminById',params)
    },
    /**
     * 获取卡片的订单
     */
    getCardCount() {
        return get("/getCardCount")
    },
    getPie() {
        return get("/getPie")
    },
    getLine() {
        return get('/getLine')
    },
    getBar() {
        return get('/getBar')
    },

    /* 
       订单管理接口 
    */

    /**
     * 获取订单信息
     */
    getAllOrder(params) {
        return get("/getAllOrder", params)
    },
    /**
     * 根据商品分类筛选表单
     */
    selectCollectionOrder(params) {
        return get("/selectCollectionOrder", params)
    },
    selectStateOrder(params) {
        return get("/selectStateOrder", params)
    },
    /**
     * 更新商品信息
     */
    updateOrder(params) {
        return post("/updateOrder", params)   //将前端获取到的params数据传递给后端
    },
    /**
     * 删除订单
     */
    delOrder(params) {
        return get("/delOrder", params)
    },
    /**
     * 获取单个商品信息
     */
    getOrderById(params) {
        return get("/getById", params)
    },


    /* 
    
    管理员接口
    
    */


    /**
     * 获取管理员列表
     */
    getAdmin(params) {
        return get("/getAdmin", params)
    },
    /**
     * 更新管理员
     */
    updateAdmin(params) {   //请求后端的updateAdmin
        return post("/updateAdmin", params)
    },
    /**
     * 删除管理员
     */
    delAdmin(params) {
        return get("/delAdmin", params)
    },
    addAdmin(params) {
        return post("/addAdmin", params)
    },


    /* 
    
    用户接口
    
    */


    /**
     * 获取用户列表
     */
    getUser(params) {
        return get("/getUser", params)
    },
    /**
     * 更新用户
     */
    updateUser(params) {
        return post("/updateUser", params)
    },
    /**
     * 删除用户
     */
    delUser(params) {
        return get("/delUser", params)
    },
    selectUser(params) {
        return get("/selectUser", params)
    },
    /** 
    *  
    */

/* 

3d展示

*/  
    getModel(){
        return get("/getModel")
    }

}

export default api