<template>
  <header>
    <el-dialog title="个人中心" :visible.sync="isShow">
      <!-- 插入组件 -->
      <commom-form :formLabel="opertateFormLabel" :form="operateForm" ref="form" :inline="true"></commom-form>
      <!-- 自定义插槽 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取消</el-button>
        <el-button type="primary" @click="comfirm">确定</el-button>
      </div>
    </el-dialog>
    <div class="l-content">
      <el-button plain icon="el-icon-menu" size="mini" @click="handleMenu"></el-button>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item >首页</el-breadcrumb-item> -->
        <el-breadcrumb-item v-for="item in tags" :key="item.path" :to="{path:item.path}">{{item.label}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="r-content">
      <el-dropdown trigger="click" szie="mini">
        <span>
          <img :src="userImg" class="user">
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="showInfo">个人中心</el-dropdown-item>
          <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </header>
</template>

<script>
import { mapState } from 'vuex'
import CommomForm from '@/components/CommomForm.vue'
import time from '@/utils/time'
export default {
  components: {
    CommomForm
  },
  data() {
    return {
      isShow: false,
      // userImg: require('../assets/images/OIP-C.jpg')
      opertateFormLabel: [
        {
          model: '_id',
          label: '编号',
          type: 'input'
        },
        {
          model: 'time',
          label: '时间',
          type: 'input'
        },
        {
          model: 'userName',
          label: '账号',
          type: 'input'
        },
        {
          model: 'password',
          label: '密码',
          type: 'input'
        },
        {
          model: 'nickName',
          label: '昵称',
          type: 'input'
        },
        {
          model: 'avator',
          label: '头像',
          type: 'avator'
        }
      ],
      operateForm: {
        _id: '',
        time: '',
        userName: '',
        password: '',
        nickName: '',
        avator: ''
      }
    }
  },
  methods: {
    handleMenu() {
      this.$store.commit('collapseMenu')
    },
    logout() {
      this.$store.commit('clearMenu')
      this.$store.commit('clearToken')
      this.$router.push({ name: 'login' })
    },
    showInfo() {
      console.log(this.userName)
      let userName = this.userName
      this.$api.getAdminById({ userName }).then(res => {
        console.log(res[0])
        res[0].time = time.formatTime(res[0].time)
        this.operateForm = res[0]
      })
      this.isShow = true
    },
    comfirm() {
      let that = this
      this.$api.updateAdmin(this.operateForm).then(res => {
        console.log(res)
        // that.userImg = res.avator
        // that.userName = res.userName
        that.$store.commit('setInfo', res)

        // this.$router.push({ name: 'login' })
        this.isShow = false
      })

      //
    }
  },

  computed: {
    // 将数据仓库的数据注入到header主件里
    ...mapState({
      tags: state => state.tab.tabList,
      userImg: state => state.user.info.avator,
      userName: state => state.user.info.userName
    })
  }
  // beforeUpdate: {
  //   ...mapState({
  //     tags: state => state.tab.tabList,
  //     userImg: state => state.user.info.avator,
  //     userName: state => state.user.info.userName
  //   })
  // }
}
</script>

<style lang="less" scoped>
header {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-content: center;
}
.l-content {
  display: flex;
  align-items: center;
  /deep/.el-button {
    margin-right: 20px;
  }
}
.r-content {
  /deep/.user {
    margin-top: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
h3 {
  display: inline-block;
  padding-left: 0.75rem;
}
/deep/.el-icon-arrow-right:before {
  content: '\e6e0';
  color: rgb(252, 248, 250);
}
/deep/div .el-dialog__header {
  text-align: left;
  margin-left: 20px;
}
/deep/div .el-dialog__body form {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
/deep/div .el-form-item__label {
  vertical-align: middle;
  margin-top: -20px;
}
</style>
