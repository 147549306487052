<template>
  <el-container style="height:100%">
    <el-aside width="auto">
      <Commom-aside></Commom-aside>
    </el-aside>
    <el-container >
      <el-header>
        <Commom-header></Commom-header>
      </el-header>
      <el-main>
        <CommonTag></CommonTag>
        <router-view></router-view>
      </el-main>
      <!-- <el-footer></el-footer> -->
    </el-container>
  </el-container>
</template>

<script>
import CommonTag from '@/components/CommonTag'
import CommomAside from '@/components/CommomAside'
import CommomHeader from '@/components/CommomHeader'
export default {
  components:{
    CommomAside,
    CommomHeader,
    CommonTag
  }
}
</script>

<style lang="less" scoped>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #545c64;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
  .el-header{
    background-color: #b9dcf5;
  }
  .el-main{
    padding-top: 0;
  }
  
</style>
