<template>
  <el-form ref="form" :model="form" label-width="100px" :inline="inline">
    <el-form-item v-for="item in formLabel" :key="item.label" :label="item.label">
      <el-input v-if="item.type == 'input' && item.model != 'password'" :placeholder="'请输入' + item.label" v-model="form[item.model]" :disabled="(item.model=='id' || item.model=='time' || item.model=='_id' ) ?true:false" :maxlength="item.model=='price'?5:11"></el-input>
      <!-- <el-switch v-model="form[item.model]" v-if="item.type ==='switch'"></el-switch>
      <el-date-picker v-if="item.type == 'date'" v-model="form[item.model]" type="date" placeholder="请选择日期" value-format="yyyy-MM-dd"></el-date-picker>-->
      <el-select v-if="item.type == 'select'" placeholder="请选择" v-model="form[item.model]">
        <el-option v-for="item in item.college" :key="item.id" :label="item.name" :value="item.name">
        </el-option>
      </el-select>

      <el-input v-if=" item.model == 'password'" :placeholder="'请输入' + item.label" v-model="form[item.model]" show-password></el-input>
      <div class="body-img">

        <!--        规定avatar类型     -->

<!--        如果图片存在      -->
        <el-image v-if="item.model=='avator' && form[item.model]" style="width: 50px; height: 50px ;margin-left:20px" :src="form[item.model]" :preview-src-list="[form[item.model]]">
        </el-image>

        <el-upload v-if="item.model=='avator' " class="avatar-uploader" :action="post" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
          <i v-if="form[item.model]" class="el-icon-edit"></i>
          <i v-else class="el-icon-plus avatar-uploader-icon"> </i>
        </el-upload>


      </div>
    </el-form-item>
    <el-form-item>
      <!-- 插槽 -->
      <slot></slot>
    </el-form-item>
  </el-form>
</template>

<script>
import host from '../config.js'
export default {
  name: 'CommomForm',
  props: {
    formLabel: Array,
    form: Object,
    inline: Boolean
  },
  data() {
    return {
      imageUrl: '',
      host: host.host,
      post: host.address + '/uploadImg'
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {   //拼接路径------------这个后判断
      console.log('图片数据为'+JSON.stringify(res));
      let { path } = res[0]
      console.log('图片路径'+path);
      console.log('主机路径'+this.host);

      this.form['avator'] = this.host + '/' + path   // 将上传成功的头像路径拼接到服务器主机地址上，并赋值给表单数据中的'avatar'字段

      console.log('要储存的路径:', this.form['avator']);   //URL 中双斜杠（//）通常表示使用相对协议

    },
    beforeAvatarUpload(file) {   //判断格式和文件大小----------------这个先判断
      const isJPG = file.type === 'image/jpeg'
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt10M   //如果符合则返回true
    }
  },
  created() {},
  destroy() {
    this.imageUrl = ''
  }
}
</script>

<style lang="less" scoped>
.body-img {
  display: flex;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.el-icon-edit,
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar {
  width: 50px;
  height: 50px;
  display: block;
}
</style>