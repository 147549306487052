<template>
  <div>

<!--           侧边栏菜单           -->
    <el-menu default-active="1-4-1" background-color="#545c64" text-color="#fff" :active-text-color="activeColor" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">

      <h3>{{isCollapse ? "后台" :"后台管理系统"}}</h3>    <!--菜单标题-->

      <!--     一级菜单     -->
      <el-menu-item @click="clickMenu(item)" v-for="item in noChilren" :index="item.path" :key="item.path">
        <i :class="'el-icon-' + item.icon"></i>
        <span slot="title">{{item.label}}</span>
      </el-menu-item>

      <!--  ------------  -->

      <!-- 包含二级导航的菜单 -->
      <el-submenu v-for="item in hasChilren" :key="item.path" :index="item.path">
        <template slot="title">
          <i :class="'el-icon-' + item.icon"></i>
          <span slot="title">{{item.label}}</span>
        </template>

        <el-menu-item-group v-for="(subitem,subIndex) in item.children" :key="subitem.path">
          <el-menu-item @click="clickMenu(subitem)" :index="subIndex.toString()">{{subitem.label}}</el-menu-item>

        </el-menu-item-group>
      </el-submenu>

    </el-menu>
<!--  --------------------------------------  -->


  </div>

</template>


<script>
export default {
  data() {
    return {
      // isCollapse: false,
      menu: [],
      activeColor: '#ffd04b'
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    clickMenu(item) {
      this.$router.push({
        name: item.name
      })
      this.$store.commit('selectMenu', item)
      console.log(item)
    },
  },
  computed: {
    noChilren() {
      // 判断是否有指向的数据源
      // filter 方法用于遍历 asyncMenu
      return this.asyncMenu.filter(item => !item.children)
    },
    hasChilren() {
      // 判断是否有指向的数据源
      return this.asyncMenu.filter(item => item.children)
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse
    },
    asyncMenu() {
      return this.$store.state.tab.menu
    }
  }
}
</script>


<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 500px;
}
.el-menu {
  text-align: initial;
  height: 100%;
  border: none;
  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
  }
}
</style>