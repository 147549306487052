<template>
  <div class="commom-tableData">
    <!-- stripe属性表示是否有斑马纹 -->
    <el-table :data="tableData" height="90%" stripe sortable>
      <el-table-column show-overflow-tooltip v-for="item in tableLabel" :key="item.prop" align="center" :label="item.label" :width="item.width ? item.width : 100" :sortable="item.sort ? true : false" :sort-method="(a, b) => {return a[item.props] - b[item.props]}">>
        <template slot-scope="scope">
          <span style="margin-left:10px" v-if="(item.prop != 'avator')">{{scope.row[item.prop]}}</span>
          <!-- <span style="margin-left:10px"  >{{scope.row[item.prop]}}</span> -->
          <el-image v-if="item.prop == 'avator'" style="width: 50px; height: 50px ;margin-left:20px" :src="scope.row[item.prop]" :preview-src-list="[scope.row[item.prop]]">
          </el-image>

        </template>
      </el-table-column>
      <el-table-column min-width="100" label="详细信息" align="center" v-if="tableType != 'admin'">
        <template slot-scope="scope">
          <el-button size="mini" class="detailBtn" @click="handleDetail(scope.row)">详细信息</el-button>
        </template>

      </el-table-column>
      <el-table-column min-width="180" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEdite(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="handleRemove(scope.row)">删除</el-button>
        </template>

      </el-table-column>

    </el-table>
    <el-pagination class="paper" layout="prev, pager, next" :total="config.total" :current-page.sync="config.page" @current-change="changePage" :page-size="10">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableData: Array,
    tableLabel: Array,
    config: Object,
    tableType: String
  },
  data() {
    return {}
  },
  methods: {
    handleEdite(row) {
      this.$emit('edit', row)
    },
    handleRemove(row) {
      this.$emit('del', row)
    },
    changePage(page) {
      this.$emit('changePage', page)
    },
    handleDetail(row) {
      this.$emit('detail', row)
    }
  }
}
</script>

<style lang="less" scoped>
.commom-tableData {
  height: calc(100% - 12px);
  background-color: #fff;
  position: relative;
  .paper {
    position: absolute;
    bottom: 0;
    right: 20px;
  }
}
.detailBtn {
  background-color: #fd9f34;
  color: white;
}
</style>