  <template>
    <div class="mange">
      <el-dialog :title="operateType === 'add' ? '新增管理员' : '更新管理员'" :visible.sync="isShow">
        <!-- 插入组件 -->
        <commom-form :formLabel="opertateFormLabel" :form="operateForm" ref="form" :inline="true"></commom-form>
        <!-- 自定义插槽 -->
        <div slot="footer" class="dialog-footer">
          <el-button @click="isShow = false">取消</el-button>
          <el-button type="primary" @click="comfirm">确定</el-button>
        </div>
      </el-dialog>
      <div class="manage-header">
        <div>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              筛选类别<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in collection" :key="index" :command="item">{{item}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <commom-form :formLabel="formLabel" :form="searchForm" ref="form" :inline="true">
          <el-button type="primary" @click="getList(searchForm.keyword)">搜索</el-button>
        </commom-form>
      </div>
      <commom-table style="height:600px" :tableData="tableData" :tableLabel="tableLabel" :config="config" @changePage="getList()" :tableType='tableType' @edit='editUser' @del="delteUser">
      </commom-table>
    </div>
  </template>

  <script>
  import CommomForm from '@/components/CommomForm.vue'
  import CommomTable from '@/components/CommomTable.vue'
  import CommomShow from '@/components/CommomShow.vue'
  import time from '../../utils/time'
  export default {
    components: {
      CommomForm,
      CommomTable,
      CommomShow
    },
    data() {
      return {
        operateType: 'add',
        tableType: 'admin',
        isShow: false,
        goodsId: '',
        collection: ['普通用户', '封禁用户', '特权用户'],
        opertateFormLabel: [
          {
            model: '_id',
            label: '用户编号',
            type: 'input'
          },
          {
            model: 'time',
            label: '更新时间',
            type: 'input'
          },
          {
            model: 'name',
            label: '昵称',
            type: 'input'
          },
          {
            model: 'root',
            label: '权限',
            type: 'select',
            college: [
              {
                name: '封禁用户',
                value: true
              },
              {
                name: '普通用户',
                value: false
              },
              {
                name: '特权用户',
                value: false
              }
            ]
          },
          {
            model: 'avator',
            label: '头像',
            type: 'avator'
          }
        ],
        operateForm: {
          _id: '',
          time: '',
          userName: '',
          root: '管理员',
          password: '',
          nickName: 0,
          avator: ''
        },
        formLabel: [
          {
            model: 'keyword',
            label: '',
            type: 'input'
          }
        ],
        searchForm: {
          keyword: ''
        },
        tableData: [],
        tableLabel: [
          {
            prop: '_id',
            label: '用户编号',
            width: 220
          },
          {
            prop: 'openid',
            label: '用户凭证',
            width: 300
          },
          {
            prop: 'time',
            label: '更新时间',
            width: 160,
            sort: true,

          },
          {
            prop: 'nickName',
            label: '昵称',
            width: 120
          },
          {
            prop: 'root',
            label: '权限',
            width: 120
          },

          {
            prop: 'avator',
            label: '头像',
            width: 120
          }
        ],
        config: {
          page: 1,
          total: 20
        }
      }
    },

    methods: {
      comfirm() {
        if (this.operateType === 'edit') {
          console.log(this.operateForm)
          this.$api.updateUser(this.operateForm).then(res => {})
          this.isShow = false
          this.getList()
        }
      },
      handleCommand(command) {
        this.$api.selectUser({ id: command }).then(res => {
          this.tableData = res.list
          this.tableData = res.list.map(item => {
            item.time = time.formatTime(Number(item.time), 'Y/M/D h:m:s')
            item.nickName = item.name
            item.avator = item.userIDImg
            return item
          })
          this.config.total = res.count
          // this.config.page += 1
          this.config.loading = false
        })
      },
      editUser(row) {
        console.log(row)
        this.isShow = true
        this.operateType = 'edit'

        this.operateForm = JSON.parse(JSON.stringify(row))
      },
      delteUser(row) {
        this.$comfirm('此操作将永久删除用户', '提示', {
          comfirmButtonText: '确认',
          canceButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const id = row._id
          this.$api
            .delUser({
              id
            })
            .then(res => {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.getList()
            })
        })
      },
      getList(name = '') {
        this.config.loading = true
        name ? (this.config.page = 1) : ''
        let page = this.config.page

        this.$api.getUser({ page, pageSize: 10, name }).then(res => {
          console.log(res)

          this.tableData = res.list
          this.tableData = res.list.map(item => {
            item.time = time.formatTime(Number(item.time), 'Y/M/D h:m:s')
            item.nickName = item.name
            item.avator = item.userIDImg
            return item
          })
          this.config.total = res.count
          // this.config.page += 1
          this.config.loading = false
        })
      }
    },
    created() {
      this.getList()
    }
  }
  </script>

  <style lang="less" scoped>
  .manage-header {
    display: flex;
    justify-content: space-between;
    max-height: 80px;
    padding: 20px 0px 0px 0px;
  }
  .el-dropdown {
    height: 20px;
    margin-right: 20px;
  }
  .el-dropdown-link {
    cursor: pointer;
    background-color: #fff;
    border: #b3d8ff 1px solid;
    color: #409eff;
    padding: 5px;
    margin-top: 10px;
    text-align: center;
    margin: 0 auto;
    line-height: 20px;
    font-size: 12px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  </style>