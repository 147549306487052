// import home from "code/api/mockServerData/home"
import jsCookie from "js-cookie"
export default{
  state:{
    isCollapse:false,
    tabList:[{
      path:'/',
      name:'home',
      label:'首页',
      icon:'home'
    }],
    currentMenu:null,
    menu:[],    //存储菜单数据的数组
  },
  mutations:{
    collapseMenu(state){
      state.isCollapse = !state.isCollapse
    },
    selectMenu(state,val){
      if(val.name !== 'home'){
        state.currentMenu = val
        const result  =  state.tabList.findIndex(item => item.name == val.name)
        if(result ===-1){
          state.tabList.push(val)
        }else{
          state.currentMenu = null
        }
        console.log(state.currentMenu );
      }
    },
    closeTag(state,val){
      const result  =  state.tabList.findIndex(item => item.name == val.name)
      state.tabList.splice(result,1)
    },
    setMenu(state,val){               //调用setMenu时把需要设置的菜单项传入进去
      state.menu = val    //将val传入menu
      jsCookie.set('menu',JSON.stringify(val))
    },
    clearMenu(state){
      state.menu = []
      jsCookie.remove('menu')
    },
    addMenu(state,router){   //添加菜单项   这里的菜单项从cookie中来的
      if(!jsCookie.get('menu')){
        return
      }
      const menu = JSON.parse(jsCookie.get('menu'))
      state.menu = menu
      const menuArray = []
      menu.forEach(item => {
        if(item.children){
          item.children = item.children.map(item=>{
            item.component = ()=> import(`../view/${item.url}`)
            return item
          })
          menuArray.push(...item.children)
        }
        else{
          item.component = ()=> import(`../view/${item.url}`)
          menuArray.push(item)
        }
      });
      menuArray.forEach(item=>{
        router.addRoute('Main',item)
      })
    }
  }
}