// const address = 'https://taoan.top/admin';
// const host = 'https://taoan.top/';


const address = 'https://serve.mrlin.online/admin';
const host = 'https://serve.mrlin.online/';
//
// const address = 'http://localhost:3000/admin';
// const host = 'http://localhost:3000/';

// const address = 'http://taoan.top/admin';
// const host = 'http://taoan.top/';
export default {
  address,    //地址
  host
}
