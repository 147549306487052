import Vue from 'vue'
import App from './App.vue'
import ElementUI, { Message, MessageBox ,Notification} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index.js'
import store from './stroe/index'
import './assets/less/index.less'
import http from 'axios'
import './Api/mock.js'
import config from './config.js'
import api from './Api/index'
Vue.config.productionTip = false
Vue.use(ElementUI)
http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
Vue.prototype.$http = http
Vue.prototype.$api = api;
Vue.prototype.$config = config;
Vue.prototype.$notify= Notification;

Vue.prototype.$comfirm = MessageBox.confirm
Vue.prototype.$message = Message


router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));   //从本地存储获取保存的用户信息
  console.log('本地获取到的信息为：'+userInfo);

  if (userInfo) {
    store.commit('setInfo', userInfo);   //将获取到的userInfo信息提交到store的setInfo
    store.commit('getToken')    //将获取到的userInfo信息提交到store的getToken
  }

  const token = store.state.user.token
  console.log('获取到的token为'+token)

  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  } else if (token && to.name === 'login') {
    next({ name: 'home' })
  } else {
    next()
  }
})

// router.addRoutes([
//   { path: '/', redirect: '/login' } // 将根路径重定向到登录页面
// ]);

new Vue({
  store,
  router,
  render: h => h(App),
  created() {
    this.$store.commit('addMenu', router)
  }
}).$mount('#app')
