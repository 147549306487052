<template>
  <div class="body">
    <div class="info">
      <div class="item" v-for="(item,index) in info" :key="index">
        <div class="key">{{item.key}}:</div>
        <div class="value" v-if="item.key!='商品图片' && item.key!='商家头像'">{{item.value}}</div>
        <el-image v-if="item.key=='商品图片' " style="width: 50px; height: 50px ;margin-left:20px" :src="item.value[0]" :preview-src-list="item.value">
        </el-image>
        <el-image v-if="item.key=='商家头像' " style="width: 50px; height: 50px ;margin-left:20px" :src="item.value" :preview-src-list="[item.value]">
        </el-image>
      </div>
      <div class="item" v-for="(item,index) in user" :key="index">
        <div class="key">{{item.key}}:</div>
        <div class="value">{{item.value}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommomShow',
  props: {
    formLabel: Array,
    form: Object,
    inline: Boolean,
    id: String
  },
  data() {
    return {
      info: {},
      user: {}
    }
  },
  methods: {
    getData() {
      this.$api.getOrderById({ id: this.id }).then(res => {
        // console.log(res);
        let data = JSON.parse(JSON.stringify(res))
        console.log('data')
        console.log(data)
        let colllection = ['学习', '数码', '装饰品', '衣物', '运动器材', '化妆品', '交通工具', '其他']
        let things = ['全新', '9成新', '8成新', '7成新', '6成新']
        const info = {
          商品标题: data.info.title,
          商品图片: data.info.detailInfo.pic,
          商品分类: colllection[Number(data.info.collegeid)],
          商品程度: things[Number(data.info.thingsid)],
          详细描述: data.info.detailInfo.describe,
          商品备注: data.info.notes,
          商家手机号: data.pulishPhone,
          商家地址: data.address,
          商家昵称: data.userInfo.nickName,
          商家头像: data.userInfo.avatarUrl
        }
        let arr = []
        for (let i in info) {
          let o = { key: i, value: info[i] }
          arr.push(o)
        }
        this.info = arr
        if (data.rate == 0) {
          data.rate = '暂无评价'
        }
        if (data.state != 1) {
          let user = {
            买家地址: data.receiveAddress,
            买家手机号: data.receivePhone,
            评价: data.rate
          }
          let arr = []
          for (let i in user) {
            let o = { key: i, value: user[i] }
            arr.push(o)
          }
          this.user = arr
        }
        else{
          this.user = []
        }
      })
    }
  },
  mounted() {
    this.getData()
  },
  beforeUpdate () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
.body {
  text-align: center;

  .info {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 300px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
    }
  }
}
</style>