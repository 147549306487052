import Vue from "vue";
import Vuex from 'vuex'
import tab from '@/stroe/tab'
import user from "@/stroe/user"
Vue.use(Vuex)
export default new Vuex.Store({
  modules:{
    tab,
    user
  }
})