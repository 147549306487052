<template>
  <div class="tabs">
    <el-tag size="small" v-for="(tag,index) in tags" :key="tag.name" :closable="tag.name !=='home'" :effect="$route.name === tag.name ? 'dark' : 'plain'
    " @click="changeMeun(tag)" @close="handleClose(tag,index)">

      {{tag.label}} </el-tag>
    <!-- <el-tag size="medium" closable>中等标签</el-tag> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapState({
      tags: state => state.tab.tabList
    })
  },
  methods: {
    ...mapMutations({
      close: 'closeTag'
    }),
    changeMeun(item) {
      this.$router.push({ name: item.name })
    },
    // 删除函数
    handleClose(tag, index) {
      const last = this.tags.length - 1
      this.close(tag)
      // 防止报错
      // console.log(tag.name);
      // console.log(this.$router.name);
      if (tag.name !== this.$route.name) {
        return
      }
      if (index === last) {
        console.log(index)
        // console.log(this.tags[index - 1].name);
        this.$router.push({
          name: this.tags[index - 1].name
        })
      } else {
        this.$router.push({
          name: this.tags[index].name
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tabs {
  padding-top: 20px;
  .el-tag {
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>