<template>
  <div ref="echart"></div>
</template>

<script>
// 封装echarts组件
import * as echarts from 'echarts'
import { init } from 'echarts'
// import func from 'vue-editor-bridge'
// 父传子 传入数据 形参为 是否包含x轴 和 表数据
export default {
  props: {
    isAxisChart: {
      type: Boolean,
      default: true
    },
    charData: {
      type: Object,
      default() {
        return {
          xData: [],
          series:[]
        }
      }
    }
  },
  data() {
    return {
      axiosOption: {
        legend: {
          // 图例文字颜色
          textStyle: {
            color: '#333'
          }
        },
        grid: {
          left: '20%'
        },
        // 提示框
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category', // 类目轴
          data: [],
          axisLine: {
            lineStyle: {
              color: '#17b3a3'
            }
          },
          axisLabel: {
            interval: 0,
            color: '#333'
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#17b3a3'
              }
            }
          }
        ],
        color: ['#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80', '#8d98b3'],
        series: []
      },
      normaOption: {
        tooltip: {
          trigger: 'item'
        },
        color: ['#0f78f4', '#dd536b', '#9462e5', '#a6a6a6', '#e1bb22', '#39c362', '#3ed1cf'],
        series: []
      },
      echart: null
    }
  },
  watch: {
    charData: {
      // 语法规范点击事件一般用Handler
      handler: function () {
        this.initChart()
      },
      deep: true
    }
  },
  methods: {
    initChart() {
      this.initChartData()
      if (this.echart) {
        this.echart.setOption(this.options)
      } else {
        // 传递dom对象
        (this.echart = echarts.init(this.$refs.echart)),
         this.echart.setOption(this.options)
      }
    },
    initChartData() {
      if (this.isAxisChart) {
        this.axiosOption.xAxis.data = this.charData.xData
        this.axiosOption.series = this.charData.series
      } else {
        this.normaOption.series = this.charData.series
      }
    }
  },
  computed: {
    options() {
      return this.isAxisChart ? this.axiosOption : this.normaOption
    }
  }
}
</script>

<style>
</style>