import Main from '../view/Main.vue'
import home from '../view/home/index.vue'
import user from '../view/user/index.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/', name: 'Main', component: Main,
    redirect: '/login',
    children: [
      {
        path: '/home',    //主页
        name: 'home',
        component: home
      },
      {
        path: '/user',    //用户页面
        name: 'user',
        component: user
      },
      {
        path: '/mall',   //商品页面
        name: 'mall',
        component: () => import('../view/mall/index.vue')
      },
      {
        path: '/admin',  //管理员页面
        name: 'admin',
        component: () => import('../view/admin/index.vue')
      },
      {
        path: '/page1',
        name: 'page1',
        component: () => import('../view/other/PageOne.vue')
      },
      {
        path: '/page2',
        name: 'page2',
        component: () => import('../view/other/PageTwo.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../view/Login/beatifuLogin.vue')   //登录界面
  },
  {
    path: '/animate',
    name: 'animate',
    component: () => import('../view/animate/animate.vue')    //动画
  }
]

const router = new VueRouter({
  routes
})

export default router
