import jsCookie from "js-cookie"
import {log} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";

export default{
  state:{
    token:'',
    nickName:'桃良长安',
    admin:'普通用户',
    info:{}
  },
  mutations:{
    setToken(state,val){
      state.token = val
      jsCookie.set('token',val)
    },
    clearToken(state){
      state.token = ''
      jsCookie.remove('token')
    },
    getToken(state){
      state.token = state.token || jsCookie.get('token')
    },
    setnickname(state,nickName){
      state.nickName = nickName
    },
    setAdmin(state,admin){
      state.admin = admin
    },
    setInfo(state,info){
      state.info = info
    }
  }
}